@font-face {
  font-family: "Jost";
  src: url("./styles/Jost-Regular.ttf") format("truetype");
  font-weight: 100;

}

@font-face {
  font-family: "Jost";
  src: url("./styles/Jost-Medium.ttf") format("truetype");
  font-weight: 600;
}


body {
  margin: 0;
  font-family: "Jost";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}
/* Font */
h1, h2, h3 {
  color: #fff;
}
h1 {
  font-size: 2.4rem;
  letter-spacing: 4px;
  margin: 5px;
  text-transform: uppercase;
}
h2 {
  font-size: 2rem;
  letter-spacing: 2px;
  font-weight: 600;
}

h3 {
  font-size: 1rem;
  letter-spacing: 3px;
  font-weight: 600;
  text-transform: uppercase;
}
h4 {
  font-size: 0.95rem;
  letter-spacing: 2px;
  font-weight: 600;
  color: #A0040C;
  text-transform: uppercase;
}

p{
  font-size: 0.95rem;
  letter-spacing: 1px;
  color: #606060;
  font-weight: 100;
}
li {
  font-size: 0.95rem;

}

a {
  font-size: 0.95rem;
  letter-spacing: 1px;
  text-decoration: none;
  font-weight: 400;
  color: #fff;
}
b {
  font-weight: 600;
}

.font__white {
 color: #fff;
}
.font__grey {
  color: #606060;
}
.font__red {
  color: #A0040C;
}
.uppercase {
  text-transform: uppercase;
}

.uppercase {
  text-transform: uppercase;
}

.font-white {
  color: #fff;
}



@media only screen and (max-width: 1024px) { 
  h1 {
   font-size: 1.8rem;
   letter-spacing: 4px;

  }
  h2{
    font-size: 1.5rem;
    letter-spacing: 2px;
   }
  
  h3 {
    font-size: 0.8rem;
    letter-spacing: 2px;
   }
   h4 {
    font-size: 0.75rem;
    letter-spacing: 1px;
   }
    
   p, a, ul, div, li {
    font-size: 0.85rem;
    letter-spacing: 0;
   }

}

@media only screen and (max-width: 767px) { 
  h1 {
   font-size: 1.4rem;
   letter-spacing: 2px;

  }
  h2{
    font-size: 1.2rem;
    letter-spacing: 2px;
   }
  
   h3 {
    font-size: 0.8rem;
    letter-spacing: 1px;
  }
   
   h4 {
    font-size: 0.7rem;
    letter-spacing: 0px;
   }
    
   p, a, ul, div, li {
    font-size: 0.8rem;
   }

}

@media only screen and (max-width: 340px) { 
  /* For seminar tiles */
   h3 {
    letter-spacing: 0px;
    word-break: break-word;
  }
}

/* Menu */
.border__bottom {
  border-bottom: 1px solid #fff;
}
.MuiDrawer-paper {
  background-color: #5D768B !important;

}

/* Akkordeon */
ul {
  padding-left: 10px;
}
li {
  color: #606060;
  list-style: "·";
  padding-left: 20px;
}
.MuiExpansionPanelSummary-expandIcon.Mui-expanded svg #Path-4 {
  display: none;
}

/* SeminarBlöcke */
.arrow__grey svg g{
  fill: #606060;
}

.seminarblock:hover svg {
  animation: arrow 2s forwards;
}

@keyframes arrow {
  0% {
    width: 20px;
  }
100% {
  width: 82px;
}
}

/* Testimonial Slider */
.CarouselItem button {

  display: none !important;
}

/* Coming Soon */
.hero--background {
  background-image: url('./images/dialog aktiv hintergrund.jpg');
  background-size: cover;

}



